<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img :src="appLogo" max-height="30px" max-width="30px" alt="logo" contain class="me-3"></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">¿Has olvidado tu contraseña?</p>
          <p class="mb-2">Ingrese tu email y te enviaremos instrucciones para restablecer su contraseña</p>
        </v-card-text>

        <!-- forgot form -->
        <v-card-text>
          <v-form ref="forgotForm" @submit.prevent="handleFormSubmit">
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="juan@gmail.com"
              :rules="[validators.required, validators.emailValidator]"
              :error-messages="errorMessages"
              class="mb-4"
              hide-details="auto"
            ></v-text-field>

            <v-btn block color="primary" :loading="buttonLoading" type="submit"> Enviar </v-btn>
          </v-form>
        </v-card-text>

        <v-card-text>
          <v-alert v-show="success" color="success" dark>
            Enviado correctamente. Revise su email incluyendo la carpeta de spam.
          </v-alert>
        </v-card-text>

        <v-card-actions class="d-flex justify-center align-center">
          <router-link :to="{ name: 'auth-login' }" class="d-flex align-center text-sm">
            <v-icon size="24" color="primary">
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Volver al Inicio de Sesión</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
import axios from '@axios'
import { emailValidator, required } from '@core/utils/validation'
import { mdiChevronLeft } from '@mdi/js'
import themeConfig from '@themeConfig'
import { ref } from 'vue'

export default {
  setup() {
    const forgotForm = ref(null)
    const isPasswordVisible = ref(false)
    const email = ref('')
    const buttonLoading = ref(false)
    const success = ref(false)
    const errorMessages = ref('')

    const handleFormSubmit = () => {
      const isFormValid = forgotForm.value.validate()

      if (!isFormValid) return

      success.value = false
      buttonLoading.value = true

      axios
        .post(`/auth/forgotPassword`, { email: email.value })
        .then(response => {
          buttonLoading.value = false
          success.value = true
          email.value = ''
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          buttonLoading.value = false
          errorMessages.value = error.response.data.message
        })
    }

    return {
      isPasswordVisible,
      email,
      buttonLoading,
      success,
      handleFormSubmit,
      forgotForm,
      errorMessages,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      validators: { required, emailValidator },

      icons: {
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
